import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '@/store/auth'

//export const store = new Vuex.Store({
export default new Vuex.Store({
  strict: true,
  state: {
  },
  modules: {
    auth
  },
  mutations: {
    
  },
  actions: {
    
  }
})
