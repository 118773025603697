import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton)
//import i18n from './i18n'     //Entry point size was 266K, so removed this after adding the content placeholders library - entry point now below 244
//import VueContentPlaceholders from 'vue-content-placeholders'

//Vue.use(VueContentPlaceholders)


Vue.config.productionTip = false
Vue.config.devtools = false



const app = new Vue({
  router,
  store,
  //i18n,
  render: h => h(App)
})






app.$mount('#app')
