//import db from '@/db'
//import firebase from '@/firebase'

import {
    SET_AUTH_STATE,
    SET_PROFILE,
    SET_ANONYMOUS_PROFILE,
    REMOVE_PROFILE,  //CLEAR_PROFILE more accurate name
    SET_PROFILE_ISANONYMOUS_FLAG,
    SET_ROLE,
    SET_LANG,
    SET_AUTO_SIGNIN_FLAG,
    SET_HAS_MINDERS,
    SET_TERMS,
    SET_EDIT_MODE,
    SET_COMMENTS_LAST_VIEWED_DATE,
    SET_ALL_COOKIES,
    SET_ESSENTIAL_COOKIES,
    SET_PERFORMANCE_COOKIES,
    SET_ANALYTIC_COOKIES,
    SET_ADVERTISING_COOKIES,
    SET_SOCIAL_COOKIES,
    SET_INITIALIZED_CONSOLE,
    SYNC_COOKIES
  } from './mutations'

  
  const state = {
    loggedIn: false,
    profile: {
      name: null,
      picture: null,
      uid: null,
      isAnonymous: false,
      accountType: null,
    },
    role: null,
    lang: null,
    autoSignInOn: false,
    hasMinders: false,
    hasInitializedConsole: false,
    userAcceptedTerms: false,
    editMode: false,
    //commentsLastViewedDate: null,
    commentsLastViewedDate: {},

    allowAllCookies: false,
    allowEssentialCookies: false,
    allowPerformanceCookies: false,
    allowAnalyticCookies: false,
    allowAdvertisingCookies: false,
    allowSocialCookies: false,
  }
  
  const getters = {
    loggedIn: ({loggedIn}) => loggedIn,
    profile: ({profile}) => profile,
    role: ({role}) => role,
    lang: ({lang}) => lang,
    autoSignInOn: ({autoSignInOn}) => autoSignInOn,
    hasMinders: ({hasMinders}) => hasMinders,
    userAcceptedTerms: ({userAcceptedTerms}) => userAcceptedTerms,
    editMode: ({editMode}) => editMode,
    commentsLastViewedDate: ({commentsLastViewedDate}) => commentsLastViewedDate,
    hasInitializedConsole: ({hasInitializedConsole}) => hasInitializedConsole,
    allowAllCookies: ({allowAllCookies}) => allowAllCookies,
    allowEssentialCookies: ({allowEssentialCookies}) => allowEssentialCookies,
    allowPerformanceCookies: ({allowPerformanceCookies}) => allowPerformanceCookies,
    allowAnalyticCookies: ({allowAnalyticCookies}) => allowAnalyticCookies,
    allowAdvertisingCookies: ({allowAdvertisingCookies}) => allowAdvertisingCookies,
    allowSocialCookies:  ({allowSocialCookies}) => allowSocialCookies,
  }
  
  const mutations = {
    [SET_TERMS] (state, bool) {
      state.userAcceptedTerms = bool
      localStorage.setItem('userAcceptedTerms', JSON.stringify(bool))
    },


    [SET_HAS_MINDERS] (state, bool) {
      state.hasMinders = bool,
      localStorage.setItem('hasMinders', JSON.stringify(bool))
    },
    [SET_AUTH_STATE] (state, bool) {
      state.loggedIn = bool
      localStorage.setItem('loggedIn', JSON.stringify(bool))
    },
    [SET_AUTO_SIGNIN_FLAG] (state, bool) {
      state.autoSignInOn = bool;
    },
    [SET_LANG] (state, lang) {
      state.lang = lang
      localStorage.setItem('lang', JSON.stringify(lang))
    },
    [SET_PROFILE_ISANONYMOUS_FLAG] (state, bool) {
      state.profile.isAnonymous = bool
    },
    [SET_PROFILE] (state, profile) {
      state.loggedIn = true
      localStorage.setItem('loggedIn', JSON.stringify(true))
      var obj = {
        displayName: (profile.displayName) ? profile.displayName : profile.providerData[0].displayName,
        photoURL: (profile.photoURL) ? profile.photoURL : profile.providerData[0].photoURL,
        uid: profile.uid,
        isAnonymous: profile.isAnonymous,
        accountType: 'heediUser',
      }
      state.profile = obj
      localStorage.setItem('profile', JSON.stringify(obj))
    },
    [SET_ANONYMOUS_PROFILE] (state, user) {
      state.loggedIn = true
      localStorage.setItem('loggedIn', JSON.stringify(true))
      var obj = {
        displayName: null,
        photoURL: null,
        uid: user.uid,
        isAnonymous: user.isAnonymous,
        accountType: 'anonymous'
      }
      state.profile = obj
      localStorage.setItem('profile', JSON.stringify(obj))
    },
    [REMOVE_PROFILE] (state) {
      state.loggedIn = false
      state.profile = {
        displayName: null,
        photoURL: null,
        uid: null,
        isAnonymous: false,
        accountType: null
      }
      localStorage.setItem('loggedIn', JSON.stringify(false))
      localStorage.setItem('profile', JSON.stringify(""))
      localStorage.setItem('role', JSON.stringify(""))
      localStorage.setItem('lang', JSON.stringify(""))
      localStorage.setItem('userAcceptedTerms', JSON.stringify(false))
      localStorage.setItem('hasMinders', JSON.stringify(false))
    },
    [SET_ROLE] (state, role) {
      state.role = role
      localStorage.setItem('role', JSON.stringify(role))
    },
    [SET_INITIALIZED_CONSOLE] (state, bool) {
      state.hasInitializedConsole = bool
      localStorage.setItem('hasInitializedConsole', JSON.stringify(bool))
    },
    [SET_EDIT_MODE] (state, bool) {
      state.editMode = bool
    },
    [SET_COMMENTS_LAST_VIEWED_DATE] (state, payload) {
      if(payload){
        // state.commentsLastViewedDate = {}    // I commented out this line to see if this removes a bug that causes the star to appear twice in a row, then not appear
        if(Object.keys(payload).length > 0) {
          Object.keys(payload).forEach((key) => {
            state.commentsLastViewedDate[key] = payload[key]
          })
        }
      }
    },
    [SET_SOCIAL_COOKIES] (state, data) {
      state.allowSocialCookies = data.allowSocialCookies
      localStorage.setItem("allowSocialCookies", JSON.stringify(data.allowSocialCookies))
    },
    [SET_ADVERTISING_COOKIES] (state, data) {
      state.allowAdvertisingCookies = data.allowAdvertisingCookies
      localStorage.setItem("allowAdvertisingCookies", JSON.stringify(data.allowAdvertisingCookies))
    },
    [SET_ANALYTIC_COOKIES] (state, data) {
      state.allowAnalyticCookies = data.allowAnalyticCookies
      localStorage.setItem("allowAnalyticCookies", JSON.stringify(data.allowAnalyticCookies))
    },
    [SET_PERFORMANCE_COOKIES] (state, data) {
      state.allowPerformanceCookies = data.allowPerformanceCookies
      localStorage.setItem("allowPerformanceCookies", JSON.stringify(data.allowPerformanceCookies))
    },
    [SET_ESSENTIAL_COOKIES] (state, data) {
      state.allowEssentialCookies = data.allowEssentialCookies
      localStorage.setItem("allowEssentialCookies", JSON.stringify(data.allowEssentialCookies))
    },
    [SET_ALL_COOKIES] (state, data) {
      state.allowAllCookies = data.allowAllCookies
      state.allowEssentialCookies = true
      state.allowPerformanceCookies = data.allowAllCookies
      state.allowAnalyticCookies = data.allowAllCookies
      state.allowAdvertisingCookies = data.allowAllCookies
      state.allowSocialCookies = data.allowAllCookies
      localStorage.setItem("allowAllCookies", JSON.stringify(data.allowAllCookies))
      localStorage.setItem("allowEssentialCookies", JSON.stringify(true))
      localStorage.setItem("allowPerformanceCookies", JSON.stringify(data.allowAllCookies))
      localStorage.setItem("allowAnalyticCookies", JSON.stringify(data.allowAllCookies))
      localStorage.setItem("allowAdvertisingCookies", JSON.stringify(data.allowAllCookies))
      localStorage.setItem("allowSocialCookies", JSON.stringify(data.allowAllCookies))

      localStorage.setItem("userAcceptsCookies", JSON.stringify(data.allowAllCookies))
    },
    [SYNC_COOKIES] (state) {
      state.allowSocialCookies = JSON.parse(localStorage.getItem("allowSocialCookies"))
      state.allowAdvertisingCookies = JSON.parse(localStorage.getItem("allowAdvertisingCookies"))
      state.allowAnalyticCookies = JSON.parse(localStorage.getItem("allowAnalyticCookies"))
      state.allowPerformanceCookies = JSON.parse(localStorage.getItem("allowPerformanceCookies"))
      state.allowEssentialCookies = JSON.parse(localStorage.getItem("allowEssentialCookies"))
      state.allowAllCookies = JSON.parse(localStorage.getItem("allowAllCookies"))
    }
  }
  
  const actions = {
    async syncCookieState ({commit}) {
      return new Promise((resolve) => {
        commit(SYNC_COOKIES)
        resolve();
      })
    },
    async setAllowAllCookies ({commit}, payload) {
      return new Promise((resolve) => {
        commit(SET_ALL_COOKIES, payload)
        resolve();
      })
    },

    async setCommentsLastViewedDate ({commit}, payload) {
      return new Promise((resolve) => {
        commit(SET_COMMENTS_LAST_VIEWED_DATE, payload)
        resolve();
      })

    },
    async setEditMode ({commit}, bool) {
      return new Promise((resolve) => {
        commit(SET_EDIT_MODE, bool)
        resolve();
      })

    },
    async setLang ({commit}, lang) {
      return new Promise((resolve) => {
        commit(SET_LANG, lang)
        resolve();
      })
    },
    async setRole ({commit}, role) {
      return new Promise((resolve) => {
        commit(SET_ROLE, role)
        resolve()
      })
    },
    async setInitializedConsole ({commit}, bool) {
      return new Promise((resolve) => {
        commit(SET_INITIALIZED_CONSOLE, bool)
        resolve()
      })
    },
    async setHasMinders ({commit}, bool) {
      return new Promise((resolve) => {
        commit(SET_HAS_MINDERS, bool)
        resolve()
      })
    },
    async setProfile ({commit}, payload) {
      return new Promise((resolve) => {
        commit(SET_PROFILE, payload)
        resolve()
      })
    },
    async removeProfile ({commit}) {
      return new Promise((resolve) => {
        commit(REMOVE_PROFILE)
        resolve()
      })
    },
    async setAnonymousProfile ({commit}, payload) {
      return new Promise((resolve) => {
        commit(SET_ANONYMOUS_PROFILE, payload)
        resolve()
      })
    },
    async setAutoSignInFlag ({commit}, bool) {
      return new Promise((resolve) => {
        commit(SET_AUTO_SIGNIN_FLAG, bool)
        resolve()
      })
    },

    async setTermsOfUse ({commit}, bool) {
      return new Promise((resolve) => {
        commit(SET_TERMS, bool)
        resolve()
      })
    },

    async syncAuthState ({commit}) {
      //Check if localStorage has been initialized
      return new Promise((resolve) => {
        if(isSupported(window.localStorage)) {
          try {
            if (localStorage.getItem("loggedIn") === null) {
              //this is a "New" User that is visiting first time or has cleared cache/files/cookies; initialize the
              //set profile key/value
              localStorage.setItem("loggedIn", JSON.stringify(false))
              //set role key/value
              localStorage.setItem("role", JSON.stringify(""))
              //set isLoggedIn key/value
              localStorage.setItem("profile", JSON.stringify(""))
              //set isLoggedIn key/value
              localStorage.setItem("userAcceptedTerms", JSON.stringify(false))
              localStorage.setItem("hasInitializedConsole", JSON.stringify(false))

              //set cookie preferences
              localStorage.setItem("allowAllCookies", JSON.stringify(true))
              localStorage.setItem("allowEssentialCookies", JSON.stringify(true))
              localStorage.setItem("allowPerformanceCookies", JSON.stringify(true))
              localStorage.setItem("allowAnalyticCookies", JSON.stringify(true))
              localStorage.setItem("allowAdvertisingCookies", JSON.stringify(true))
              localStorage.setItem("allowSocialCookies", JSON.stringify(true))

            } else {
              //this is a Returning User that has had localStorage initialized; let's see if they are logged in
              //Get value of 'loggedIn' from localStorage
              var loggedIn = JSON.parse(localStorage.getItem("loggedIn"))
              var role = JSON.parse(localStorage.getItem("role"))
              var profile = JSON.parse(localStorage.getItem("profile"))
              var hasInitializedConsole = JSON.parse(localStorage.getItem("hasInitializedConsole"))
              var userAcceptedTerms = JSON.parse(localStorage.getItem("userAcceptedTerms"))
              //Sync values with auth module state
              commit(SET_AUTH_STATE, loggedIn)


              //commit(SET_ALL_COOKIES, { allowAllCookies: JSON.parse(localStorage.getItem("allowAllCookies")) })
              //commit(SET_ESSENTIAL_COOKIES, { allowEssentialCookies: JSON.parse(localStorage.getItem("allowEssentialCookies")) })
              //commit(SET_PERFORMANCE_COOKIES, { allowPerformanceCookies: JSON.parse(localStorage.getItem("allowPerformanceCookies")) })
              //commit(SET_ANALYTIC_COOKIES, { allowAnalyticCookies: JSON.parse(localStorage.getItem("allowAnalyticCookies")) })
              //commit(SET_ADVERTISING_COOKIES, { allowAdvertisingCookies: JSON.parse(localStorage.getItem("allowAdvertisingCookies")) })
              //commit(SET_SOCIAL_COOKIES, { allowSocialCookies: JSON.parse(localStorage.getItem("allowSocialCookies")) })

              if(loggedIn) {
                //need to set profile pic

                if(profile.isAnonymous === true) {
                  commit(SET_ANONYMOUS_PROFILE, profile)
                } else {
                  commit(SET_PROFILE, profile)
                }

                commit(SET_ROLE, role)

                if(hasInitializedConsole) {
                  commit(SET_INITIALIZED_CONSOLE, hasInitializedConsole)
                }
                
                
                if(userAcceptedTerms) {
                  commit(SET_TERMS, true)
                }

                
              }

              //dispatch('signin/autoSignin', null, {root: true})   //cannot put this here because the signin module has not been initialized
              //if I do initialize the signin module, it will add 600kb to entry point due to firebase library
            }
          } catch(e) {
            /* eslint-disable no-console */
            console.log(e)
          }
        }
        //handles any inconsistencies with how localStorage is implemented across browsers
        function isSupported(storage) {
          try {
            const key = "__some_random_key_you_are_not_going_to_use__";
            storage.setItem(key, key);
            storage.removeItem(key);
            return true;
          } catch (e) {
            return false;
          }
        }
        resolve()
      })
    },
  
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }