import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          resolve({ x: 0, y: 0 })
        }
      }, 200)
    })
  },
  routes: [
    {
      path: '/',
      name: 'home',
      beforeEnter: checkRedirectToConsole,
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    },
    {
      path: '/account',
      name: 'account',
      beforeEnter: checkAuth3,
      component: () => import(/* webpackChunkName: "account" */ '@/views/Account.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
    },
    {
      path: '/admin/sequences/',
      name: 'admin5',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSequences.vue')
    },
    {
      path: '/admin/sequences/:command/:type',
      name: 'admin4',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminSequences.vue')
    },

    {
      path: '/admin/routines/',
      name: 'admin6',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminRoutines.vue')
    },
    {
      path: '/admin/routines/:command/:type',
      name: 'admin7',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminRoutines.vue')
    },
    {
      path: '/admin/actions/',
      name: 'admin8',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminHumanActions.vue')
    },
    {
      path: '/admin/actions/:command/:type',
      name: 'admin9',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminHumanActions.vue')
    },

    {
      path: '/admin/ingredients/',
      name: 'admin10',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminIngredients.vue')
    },
    {
      path: '/admin/ingredients/:command/:type',
      name: 'admin11',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminIngredients.vue')
    },

    {
      path: '/admin/meals/',
      name: 'admin12',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminMeals.vue')
    },
    {
      path: '/admin/meals/:command/:type',
      name: 'admin13',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/AdminMeals.vue')
    },
    
    {
      path: '/admin/:command/:type/',
      name: 'admin2',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
    },
    {
      path: '/admin/:command/:type/:resourceId',
      name: 'admin3',
      beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "admin" */ '@/views/Admin.vue')
    },
    {
      path: '/check-in/:notifId/:userUid',
      name: 'check-in',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "check-in" */ '@/views/Checkin.vue')
    },
    
 
    {
      path: '/console',
      name: 'console',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "console" */ '@/views/Console.vue')
    },
    {
      path: '/console/:command/:type/:resourceId',
      name: 'console2',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "console" */ '@/views/Console.vue')
    },
    {
      path: '/console/:command/:type/:resourceId/:title',
      name: 'console3',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "console" */ '@/views/Console.vue')
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: () => import(/* webpackChunkName: "cookies" */ '@/views/CookiePolicy.vue')
    },
    {
      path: '/did-it/:userUid/:dayStamp/:routineTemplateMomentId',
      name: 'did-it',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "did-it" */ '@/views/DidIt.vue')
    },
    {
      path: '/evolve/:routineId',
      name: 'evolve',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "evolve" */ '@/views/Evolve.vue')
    },
    {
      path: '/evolve/:category/:routineId',
      name: 'evolve2',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "evolve" */ '@/views/Evolve.vue')
    },
    {
      path: '/follow-up/:nextStepId',
      name: 'follow-up',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "follow-up" */ '@/views/Followup.vue')
    },
    {
      path: '/group/:hashtag',
      name: 'group',
      component: () => import(/* webpackChunkName: "group" */ '@/views/Group.vue')
    },
    //{
    //  path: '/heedar',
    //  name: 'heedar',
      //beforeEnter: checkAuth,
    //  component: () => import(/* webpackChunkName: "heedar" */ '@/views/Heedar.vue')
    //},
    {
      path: '/hits',
      name: 'hits',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "hits" */ '@/views/Hits.vue')
    },   
    {
      path: '/moderation',
      name: 'moderation',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "moderation" */ '@/views/Moderation.vue')
    },
    {
      path: '/mturk',
      name: 'mturk',
      component: () => import(/* webpackChunkName: "mturk" */ '@/views/MTurk.vue')
    },
    {
      path: '/muse/:articleSlug',
      name: 'muse',
      component: () => import(/* webpackChunkName: "muse" */ '@/views/Muse.vue')
    },
    {
      path: '/muse',
      name: 'muse2',
      component: () => import(/* webpackChunkName: "muse" */ '@/views/Muse.vue')
    },
    {
      path: '/partner',
      name: 'partner',
      component: () => import(/* webpackChunkName: "partner" */ '@/views/Partner.vue')
    },
    {
      path: '/publish',
      name: 'publish',
      beforeEnter: checkAuth2,
      component: () => import(/* webpackChunkName: "publish" */ '@/views/Publish.vue')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import(/* webpackChunkName: "terms" */ '@/views/TermsOfUse.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "privacy" */ '@/views/PrivacyPolicy.vue')
    },
    
    {
      path: '/routine',
      name: 'routine1',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "routine" */ '@/views/Routine.vue')
    },
    {
      path: '/routine/:command',
      name: 'routine2',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "routine" */ '@/views/Routine.vue')
    },
    {
      path: '/routine/:command/:type/',
      name: 'routine3',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "routine" */ '@/views/Routine.vue')
    },
    {
      path: '/routine/:command/:type/:resourceId',
      name: 'routine4',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "routine" */ '@/views/Routine.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue')
    },
    {
      path: '/support',
      name: 'support',
      //beforeEnter: checkAuth,
      component: () => import(/* webpackChunkName: "support" */ '@/views/Support.vue')
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () => import(/* webpackChunkName: "sign-in" */ '@/views/Signin.vue')
    },
    {
      path: '/start',
      name: 'start',
      component: () => import(/* webpackChunkName: "start" */ '@/views/Start.vue')
    },

    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import(/* webpackChunkName: "subscribe" */ '@/views/Subscribe.vue')
    },
    {
      path: '/unsubscribe/:emailId',
      name: 'unsubscribe',
      component: () => import(/* webpackChunkName: "unsubscribe" */ '@/views/Unsubscribe.vue')
    },
    {
      path: '*',
      component: Home  //this needs to be revised 404 where we try to turn the wildcard into a taxonomy item
    }
  ]
})


function checkAuth(to, from, next) {
  //const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  //console.log("checkAuth called")
  //console.log("store.state.auth.loggedIn:  "+store.state.auth.loggedIn)
  //const isAuthenticated = firebase.auth().currentUser     //I removed the firebase libary because that would add 500Kb to the entry point
  if (!store.state.auth.loggedIn) {                          //Instead, I use a vuex object to hold sign-in state
    //next('/signin?to='+to.path)
    next('/')
  } else {
    if(store.state.auth.role == 'superDuperAdmin') {
      //continue on
      next()
    }else{
      next('/')
    }
  }
}


function checkAuth2(to, from, next) {
  if (!store.state.auth.loggedIn) {                          //Instead, I use a vuex object to hold sign-in state
    //next('/signin?to='+to.path)
    next('/')
  } else {
    if(store.state.auth.role == 'publisher' || store.state.auth.role == 'superDuperAdmin') {
      next()
    }else{
      next('/')
    }
  }
}



function checkRedirectToConsole(to, from, next) {
  if(isSupported(window.localStorage)) {
    if(to.query.d != "1") {
      if (JSON.parse(localStorage.getItem("hasInitializedConsole")) === true) {
        /* eslint-disable no-console */
        //console.log("pushing to console")
        //console.log("to", to)
  
        next('/console')
      }else {
        next()
      }
    }else {
      next()
    }
  }
  function isSupported(storage) {
    try {
      const key = "__some_random_key_you_are_not_going_to_use__";
      storage.setItem(key, key);
      storage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }
}

function checkAuth3(to, from, next) {
  if (!store.state.auth.loggedIn) {                          //Instead, I use a vuex object to hold sign-in state
    //next('/signin?to='+to.path)
    next('/')
  } else {
    next()
  }
}