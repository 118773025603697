<template>

  <div class="home">
    <div id="searchBox">
        <!--
        <NewSearch v-if="showSearch"  style="background-color: white;"/>
        -->
        <RoutineSearchHome 
          v-if="showSearch" 
          style="background-color: white;"
        />
    </div>
    
    
    
    
      <FeaturedArticles />
    
      <!--
        <div class="updates">
            <router-link to="/about">
              <div class="updateHolder">
                <div class="starHolder">
                  <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
                </div>
                <span class="headlineText">A new kind of clock!</span>&nbsp;&nbsp;
                <span class="dateText">2021-07-28</span>
                <div class="updateText">
                  Introducing heedi, a platform and timing-tool for expanding your mindfulness of more routines than you thought possible.&nbsp;&nbsp;Click to find out more.
                </div>
              </div>
            </router-link>
          
        </div>
      
    
      <div class="updates">
        <div class="updateHolder">
          <div class="starHolder">
            <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
          </div>
          <span class="headlineText">heedi is in Beta Release</span>&nbsp;&nbsp;
            <span class="dateText">2021-07-28</span>
          <div class="updateText">
            heedi is actively being developed with new features and routines added every week.  Please stay with us.  
          </div>
        </div>  
 
      </div>
      -->
    



    
      <FeaturedRoutines />
      
    
      <!--
      <router-link to="/evolve/start-purpose">
        <div class="card" v-on:click="gtag1">
          
          <div class="container">
            <b>{{this.startButtonText}}</b>
          </div>
          
        </div>
      </router-link>
      -->
      
      
      <br />   
      <div id="routineDataList"  v-on:click="overlayOff">
        <div class="routineDataClass">
          <div v-for="(routine, index) in routineList" :key="index">
            {{ routine.t }}
          </div>
        </div>
      </div>
      <br />
      <!--
      <MevChains v-on:load-finished="showFooter"/>
      -->
      <br />
      <br />
      
      <!--
      <VueFooter v-if="showFooterBool"/>
      -->
      <VueFooter />
       
     
    
  </div>
  
</template>

<script>
  //import mevChainModule from '@/store/mev_chain'   can't do this because it would require the firebase libraries to load on the homepage, and I'm avoiding that
  import { mapGetters, mapActions } from 'vuex'
  //import MevChains from '@/components/MevChains'
  //import SixArticles from '@/components/SixArticles'
  import FeaturedRoutines from '@/components/FeaturedRoutines'
  import FeaturedArticles from '@/components/FeaturedArticles'
  import RoutineSearchHome from '@/components/RoutineSearchHome'
  //import NewSearch from '@/components/NewSearch'
  //import cmsModule from '@/store/cms'
  import VueFooter from '@/components/VueFooter'

  export default {
    name: "home",
    components: {
      //MevChains,
      VueFooter,
      //SixArticles,
      //NewSearch,
      FeaturedRoutines,
      FeaturedArticles,
      RoutineSearchHome
    },
    data () {
      return {
        showFooterBool: false,
        showSearch: true,
        showRoutineData: false,
        routineList: [
          {
            t: 'Drink water, 9 glasses per day  - everyday habit routine', 
            l: 'https://heedi.com/routines/everyday_habit/948u4owiuf34ufoupm'
          },
          {
            t: 'Drink more water, 10 glasses per day  - everyday habit routine', 
            l: 'https://heedi.com/routines/everyday_habit/948u4owiuf34ufoupm'
          },
          {
            t: 'Reduce smoking, 10 times per day to 3 per day - cessation routine', 
            l: 'https://heedi.com/routines/cessation/9348f0438j5f09348j5'
          },
          {
            t: 'Meal Plan #1, 8 dishes cooked over 3 day period - cooking routine', 
            l: 'https://heedi.com/routines/cessation/9348f0438j5f09348j5'
          },

        ],
        
      }
    },
    computed: {
      ...mapGetters('cms', {
        startButtonText: 'startButtonText',
      }),
      
    },
    methods: {
      ...mapActions('search', [
        'setShowSearchIcon'
      ]),
      updateLayout () {
        this.$emit('close-mobile-menu', null)
      },
      overlayOff () {
        document.getElementById("routineDataList").style.display = "none"
      },
      
      showFooter () {
        this.showFooterBool = true
      },

      showRoutineList () {

      },
    },
   
    beforeCreate () {
      //if(!this.$store.state.mev_chain) this.$store.registerModule('mev_chain', mevChainModule)
      //if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
    },
    async created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/home'});
      this.setShowSearchIcon(false)
      
      
    },
    async mounted () {
      
    },
    updated () {

    },
    watch: {

    }
  
}
</script>


<style scoped>
#routineDataList {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.95); /* Black background with opacity */
  z-index: 3000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.routineDataClass {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 8px;
  background-color: transparent;
  z-index: 100;
}

.card {
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  display: inline-block;
  background-color: rgb(109, 165, 248);
  text-align: center;
  font-size: 1.6em;
  color: #283f63;
  
  border-radius: 20px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 8px 48px;
}

.home {
  margin: 0 auto;
  text-align: center;
  max-width: 1300px;
}

a {
  color: inherit;
  text-decoration: none;
}



  











</style>
