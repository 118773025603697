//import db from '@/db'
// import firebase from '@/firebase'

import {
    SET_CMS_CONTENT
  } from './mutations'
  
  const state = {
    //Home Page
    startButtonText: null,

    //Search
    searchStimulusText: null,

    //Start Page
    intentMeanStatementStimulusText: null,
    intentMeanStatementStimulusHint: '',
    endStatementStimulusText: null,
    endStatementStimulusHint: '',
    valueStatementStimulusText: null,
    valueStatementStimulusHint: '',
    nextStepStimulusText: null,
    nextStepStimulusHint: '',
    actionMeanStatementStimulusText: null,
    actionMeanStatementStimulusHint: '',


    intentMeanStatementStepText: null,
    endStatementStepText: null,
    valueStatementStepText: null,
    nextStepStepText: null,
    actionMeanStatementStepText: null,


    //Console
    toxicityChecklistForwardButtonText: null,
    toxicityChecklistBackButtonText: null,
    toxicityChecklistForwardStatementText: null,
    toxicityChecklistBackStatementText: null,

    //Checkin -> FeelingIntake
    checkInFeelingContent: {
      feelingHappinessText: null,
      feelingHappinessMeasure: null,
      feelingMotivationText: null,
      feelingMotivationMeasure: null,
      feelingConfidenceText: null,
      feelingConfidenceMeasure: null
    },
    
    //Checkin -> ThoughtIntake
    checkInThoughtContent: {
      thoughtStimulusQuestion: null,
    },

    //Communicate - feedbackOnheedi -> FeelingIntake
    feedbackFeelingContent: {
      feelingHappinessText: null,
      feelingHappinessMeasure: null,
      feelingMotivationText: null,
      feelingMotivationMeasure: null,
      feelingConfidenceText: null,
      feelingConfidenceMeasure: null
    },
    
    //Communicate - feedbackOnheedi -> ThoughtIntake
    feedbackThoughtContent: {
      thoughtStimulusQuestion: null,
    },

    //Follow-up -> NextStepper > FeelingIntake
    followUpNextStepFeelingContent: {
      feelingText: null,
      measure: null,
    },
    //Follow-up -> NextStepper > ThoughtIntake
    followUpNextStepThoughtContent: {
      stimulusQuestion: null,
    },


    

    //MinderCard -> NextStepper
    nextStepperContent: {
      nextStepStimulusQuestion: null,
    },

    notificationJournalCheckinBody: null,
    notificationJournalCheckinTitle: null,

    //MinderCard -> MomentMaker
    momentContent: {
      scheduleMomentStimulusQuestion: null,
    },


    //Evolve.vue
    tipJarContent: {
      tipStimulusQuestion: null,
      tipStimulusSubTitle: null,
    },


    actionChainsContent: {
      actionChainsStimulusQuestion: null,
      actionChainsStimulusSubTitle: null,
    }

    

  }
  
  const getters = {
    startButtonText: ({startButtonText}) => startButtonText,

    searchStimulusText: ({searchStimulusText}) => searchStimulusText,

    intentMeanStatementStimulusText: ({intentMeanStatementStimulusText}) => intentMeanStatementStimulusText,
    intentMeanStatementStimulusHint: ({intentMeanStatementStimulusHint}) => intentMeanStatementStimulusHint,

    endStatementStimulusText: ({endStatementStimulusText}) => endStatementStimulusText,
    endStatementStimulusHint: ({endStatementStimulusHint}) => endStatementStimulusHint,

    valueStatementStimulusText: ({valueStatementStimulusText}) => valueStatementStimulusText,
    valueStatementStimulusHint: ({valueStatementStimulusHint}) => valueStatementStimulusHint,

    nextStepStimulusText: ({nextStepStimulusText}) => nextStepStimulusText,
    nextStepStimulusHint: ({nextStepStimulusHint}) => nextStepStimulusHint,

    actionMeanStatementStimulusText: ({actionMeanStatementStimulusText}) => actionMeanStatementStimulusText,
    actionMeanStatementStimulusHint: ({actionMeanStatementStimulusHint}) => actionMeanStatementStimulusHint,

    meanStatementStepText: ({meanStatementStepText}) => meanStatementStepText,
    endStatementStepText: ({endStatementStepText}) => endStatementStepText,
    valueStatementStepText: ({valueStatementStepText}) => valueStatementStepText,
    nextStepStepText: ({nextStepStepText}) => nextStepStepText,
    actionMeanStepText: ({actionMeanStepText}) => actionMeanStepText,


    toxicityChecklistBackButtonText: ({toxicityChecklistBackButtonText}) => toxicityChecklistBackButtonText,
    toxicityChecklistForwardButtonText: ({toxicityChecklistForwardButtonText}) => toxicityChecklistForwardButtonText,
    toxicityChecklistForwardStatementText: ({toxicityChecklistForwardStatementText}) => toxicityChecklistForwardStatementText,
    toxicityChecklistBackStatementText: ({toxicityChecklistBackStatementText}) => toxicityChecklistBackStatementText,

    checkInFeelingContent: ({checkInFeelingContent}) => checkInFeelingContent,
    checkInThoughtContent: ({checkInThoughtContent}) => checkInThoughtContent,

    feedbackFeelingContent: ({feedbackFeelingContent}) => feedbackFeelingContent,
    feedbackThoughtContent: ({feedbackThoughtContent}) => feedbackThoughtContent,

    followUpNextStepFeelingContent: ({followUpNextStepFeelingContent}) => followUpNextStepFeelingContent,
    followUpNextStepThoughtContent: ({followUpNextStepThoughtContent}) => followUpNextStepThoughtContent,
    

    nextStepperContent: ({nextStepperContent}) => nextStepperContent,

    notificationJournalCheckinBody: ({notificationJournalCheckinBody}) => notificationJournalCheckinBody,
    notificationJournalCheckinTitle: ({notificationJournalCheckinTitle}) => notificationJournalCheckinTitle,

    momentContent: ({momentContent}) => momentContent,


    tipJarContent:({tipJarContent}) => tipJarContent,

    actionChainsContent: ({actionChainsContent}) => actionChainsContent,


    //add more here
  }
  
  const mutations = {
    
    [SET_CMS_CONTENT] (state, data) {
      
      state.startButtonText = data['Home_Page'].startButtonText

      state.searchStimulusText = data['Search'].searchStimulusText

      state.intentMeanStatementStimulusText = data['Start_Page'].intentMeanStatementStimulusText
      state.intentMeanStatementStimulusHint = data['Start_Page'].intentMeanStatementStimulusHint
      state.endStatementStimulusText = data['Start_Page'].endStatementStimulusText
      state.endStatementStimulusHint = data['Start_Page'].endStatementStimulusHint
      state.valueStatementStimulusText = data['Start_Page'].valueStatementStimulusText
      state.valueStatementStimulusHint = data['Start_Page'].valueStatementStimulusHint
      state.nextStepStimulusText = data['Start_Page'].nextStepStimulusText
      state.nextStepStimulusHint = data['Start_Page'].nextStepStimulusHint
      state.actionMeanStatementStimulusText = data['Start_Page'].actionMeanStatementStimulusText
      state.actionMeanStatementStimulusHint = data['Start_Page'].actionMeanStatementStimulusHint
      


      state.intentMeanStatementStepText = data['Start_Page'].intentMeanStatementStepText
      state.endStatementStepText = data['Start_Page'].endStatementStepText
      state.valueStatementStepText = data['Start_Page'].valueStatementStepText
      state.nextStepStepText = data['Start_Page'].nextStepStepText
      state.actionMeanStatementStepText = data['Start_Page'].actionMeanStatementStepText


      state.toxicityChecklistBackButtonText = data['Console_Page'].MinderCard.ToxicityChecklist.toxicityChecklistBackButtonText
      state.toxicityChecklistForwardButtonText = data['Console_Page'].MinderCard.ToxicityChecklist.toxicityChecklistForwardButtonText
      state.toxicityChecklistForwardStatementText = data['Console_Page'].MinderCard.ToxicityChecklist.toxicityChecklistForwardStatementText
      state.toxicityChecklistBackStatementText = data['Console_Page'].MinderCard.ToxicityChecklist.toxicityChecklistBackStatementText

      state.momentContent.scheduleMomentStimulusQuestion = data['Console_Page'].MomentMaker.scheduleMomentStimulusQuestion

      state.followUpNextStepFeelingContent.feelingText = data['Console_Page'].NextStepper.OutcomeIntake.FeelingIntake.feelingText
      state.followUpNextStepFeelingContent.measure = data['Console_Page'].NextStepper.OutcomeIntake.FeelingIntake.measure

      state.followUpNextStepThoughtContent.stimulusQuestion = data['Console_Page'].NextStepper.OutcomeIntake.ThoughtIntake.stimulusQuestion

      state.checkInFeelingContent.feelingHappinessText = data['Check-in_Page'].FeelingIntake.feelingHappinessText
      state.checkInFeelingContent.feelingHappinessMeasure = data['Check-in_Page'].FeelingIntake.feelingHappinessMeasure
      state.checkInFeelingContent.feelingConfidenceText = data['Check-in_Page'].FeelingIntake.feelingConfidenceText
      state.checkInFeelingContent.feelingConfidenceMeasure = data['Check-in_Page'].FeelingIntake.feelingConfidenceMeasure
      state.checkInFeelingContent.feelingMotivationText = data['Check-in_Page'].FeelingIntake.feelingMotivationText
      state.checkInFeelingContent.feelingMotivationMeasure = data['Check-in_Page'].FeelingIntake.feelingMotivationMeasure

      state.checkInThoughtContent.thoughtStimulusQuestion = data['Check-in_Page'].ThoughtIntake.thoughtStimulusQuestion

      state.feedbackThoughtContent.thoughtStimulusQuestion = data['Communicate_Page'].ThoughtIntake.thoughtStimulusQuestion

      state.feedbackFeelingContent.feelingHappinessText = data['Communicate_Page'].FeelingIntake.feelingHappinessText
      state.feedbackFeelingContent.feelingHappinessMeasure = data['Communicate_Page'].FeelingIntake.feelingHappinessMeasure
      state.feedbackFeelingContent.feelingConfidenceText = data['Communicate_Page'].FeelingIntake.feelingConfidenceText
      state.feedbackFeelingContent.feelingConfidenceMeasure = data['Communicate_Page'].FeelingIntake.feelingConfidenceMeasure
      state.feedbackFeelingContent.feelingMotivationText = data['Communicate_Page'].FeelingIntake.feelingMotivationText
      state.feedbackFeelingContent.feelingMotivationMeasure = data['Communicate_Page'].FeelingIntake.feelingMotivationMeasure

      state.nextStepperContent.nextStepStimulusQuestion = data['NextStepper'].nextStepStimulusQuestion

      state.notificationJournalCheckinBody = data['Notifications'].Journal_Checkin.body
      state.notificationJournalCheckinTitle = data['Notifications'].Journal_Checkin.title

      state.tipJarContent.tipStimulusQuestion = data['TipJar'].tipStimulusQuestion
      state.tipJarContent.tipStimulusSubTitle = data['TipJar'].tipStimulusSubTitle

      state.actionChainsContent.actionChainsStimulusQuestion = data['ActionChains'].actionChainsStimulusQuestion
      state.actionChainsContent.actionChainsStimulusSubTitle = data['ActionChains'].actionChainsStimulusSubTitle
      




      //add more here
    }
  }
  
  const actions = {
    setCmsContent ({commit}, payload) {
      commit(SET_CMS_CONTENT, payload)
    },
    /* setProfile ({commit}, payload) {
      return new Promise((resolve, reject) => {
        
        commit(SET_PROFILE, payload)
        resolve()
      })
    } */
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }