<template>

<div>
  
  
  
  <!--<LanguageSelector  v-if="showLanguageSelector & $route.path !== '/mturk'" v-on:closeLanguageSelector="closeLanguageSelector" />-->
  
  <header>
    
    <div id="logo"><router-link :to="{path:'/', query: {d: 1}}"><a href="javascript:void(0);" @click="closeMenus()"><img :src="require('@/assets/heedi_homepage_logo_red2.png')" height="60" alt="heedi"></a></router-link>
    
      <!-- &nbsp;<span style="font-size: .6em;color: grey;">BETA</span> -->
    </div>
    
      
    <div id="mainNav" class="menuUp">

      <div id="navToggle" v-on:click="showMobileMenuFunc">
        <div class="hamburgerIcon">
          <i class="fa fa-bars"></i>
        </div>
      </div>
      
      <div id="searchIcon"  v-if="showSearchIcon"><a href="javascript:void(0);" v-on:click="mySearchIconFunction"><i class="fas fa-search"></i></a></div>
      
      <!--<div id="ideaIcon" v-if="loggedIn"><router-link to="/follow-up/onInitiative"><a href="#" v-on:click="onIdeaIconClick();responsiveMenuFunc()"><img :src="require('@/assets/shooting_star.png')" height="20" alt="heedi"></a></router-link></div> -->
        
    </div>
    
    <nav>
      <transition name="fade">
      <ul v-if="showMenu" >
        
        <li v-if="loggedIn && (profile.accountType != null)" v-on:click="showProfileMenuFunc">
          <a href="javascript:void(0);">
            <img v-if="profile.accountType==='heediUser'" v-bind:src="profile.photoURL" height="48" alt="profile">
            <img v-if="profile.isAnonymous" v-bind:src="require('@/assets/anonymous_profile_pic.png')" height="48" alt="profile">
          </a>
        </li>
        <li>
          
          <nav>
            <ul>
              
              <transition name="fade">
              <li v-if="loggedIn && showProfileMenu && !profile.isAnonymous" v-on:click="logout();responsiveMenuFunc()"><router-link to="/">Logout</router-link></li>
              </transition>
            </ul>
          </nav>
          
        </li>
        <!-- Keep below line commented out for awhile -->
        <!-- <li><router-link to="/start"><i class="fas fa-arrow-right fa-xs"></i>&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('app-topnav-start') }}</router-link></li> -->
        
        <li v-if="!loggedIn" @click="responsiveMenuFunc"><router-link to="/sign-in">Login</router-link></li>
        <li v-if="loggedIn && (this.$store.state.auth.role === 'superDuperAdmin')" @click="responsiveMenuFunc"><router-link to="/admin">Admin</router-link></li>
        <!-- <li v-if="loggedIn && (this.$store.state.auth.role === 'superDuperAdmin' || this.$store.state.auth.role === 'publisher')" @click="responsiveMenuFunc"><router-link to="/publish">Publish</router-link></li> -->
        <!--
        <li v-if="loggedIn && (this.$store.state.auth.role === 'superDuperAdmin')" @click="responsiveMenuFunc"><router-link to="/moderation">Moderation</router-link></li>
        <li v-if="loggedIn && (this.$store.state.auth.role === 'superDuperAdmin')" @click="responsiveMenuFunc"><router-link to="/hits">Hits</router-link></li>
        -->
        <li v-if="loggedIn" @click="responsiveMenuFunc"><router-link to="/account">Account</router-link></li>
        <li v-if="loggedIn" @click="responsiveMenuFunc"><router-link to="/settings">Settings</router-link></li>
        <li v-if="loggedIn && hasInitializedConsole" @click="responsiveMenuFunc"><router-link to="/support">How-To</router-link></li>
        <li v-if="loggedIn && hasInitializedConsole" @click="responsiveMenuFunc"><router-link to="/console">Heedar Console</router-link></li>
        <!-- <li @click="responsiveMenuFunc"><router-link to="/routine">Your Routine</router-link></li> -->
        
        <li id="searchIcon2"><a href="javascript:void(0);" v-on:click="mySearchIconFunction" v-if="showSearchIcon"><i class="fas fa-search"></i></a></li>
        <!--<li id="ideaIcon2"><router-link to="/follow-up/onThought"><a href="javascript:void(0);" v-on:click="onIdeaIconClick();"><i class="far fa-lightbulb"></i></a></router-link></li>-->

      </ul>
      </transition>
    </nav>
    
  </header>
  
    <main>
      <transition mode="out-in">
        <router-view @close-mobile-menu="closeMenus" :key="$route.fullPath" class="mainArea"></router-view>
      </transition>
    </main>
  
  
  <div class="bottomCookieNotice" id="bottomCookieNotice" v-if="showCookieOverlay">
    <div class="overlayLogo">
      <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
    </div>
    <i id="cookieNoticeClose" class="fas fa-times" v-on:click="closeCookieOverlay"></i>
    
    
    <div class="cookiePrefBtn" @click="onCookiePref">
      Cookie Settings
    </div>
    
    <div class="cookieDeclineBtn" @click="onCookieDecline">
      Decline Cookies
    </div><br>
    <div class="cookieAcceptBtn" @click="onCookieAccept">
      Accept Cookies
    </div>
    <div class="cookieNoticeText">
      Please <a style="color: #999;" href="javascript:void(0);" @click="onCookiePolicyClick">read our cookie policy</a> for more information. By browsing our website, you consent to our use of cookies and other tracking technologies. 
    </div>
    
  </div>
  

  <div class="demoOverlay" id="demoOverlay" v-if="showDemoOverlay">
    <div class="demoOverlayLogo">
      <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
    </div>
    <div class="codeInputHolder">
      <!-- label -->
      <div class="codeInputLabel">enter passcode</div>
      <!-- textfield -->
      <input class="demoOverlayTextInput" type="text" title="enter passcode" id="codeInput" v-model="passcodeValue">
      <!-- button -->
      <button class="demoOverlayButton" type="button" @click="onPasscodeSubmit">GO</button>
      <br />
      <br />
      <br />
      <br />
      <div class="demoOverlayNotice">
        
      </div>
    </div>
  </div>
  
    
  
  
</div>
</template>

<script>
//import NewSearch from './components/NewSearch'
//import LanguageSelector from './components/LanguageSelector'
import authModule from '@/store/auth'
import cmsModule from '@/store/cms'
import searchModule from '@/store/search'
//import categoryModule from '@/store/category'
//import signinModule from '@/store/signin'
//import LocaleSwitcher from './components/LocaleSwitcher'
import { mapActions, mapGetters } from 'vuex'
import Worker from "worker-loader!@/worker";

export default {
  name: 'App',
  components: {
    //NewSearch,
    //LanguageSelector
  },
  data () {
    return {
      showDemoOverlay: false,
      //demoPasscode: "sxsw2020demo",
      showSearch: false,
      showProfileMenu: false,
      showLanguageSelector: true,
      showMenu: true,
      isMobile: false,
      windowWidth: 0,
      showFooter: false,

      passcodeValue: null,
      passcode: '2021demo1379',

      showCookieOverlay: true,

    }
  },
  computed: {
    ...mapGetters('auth', [
      'profile',
      'loggedIn',
      'hasInitializedConsole',
      
    ]),
    ...mapGetters('search', [
      'showSearchIcon',
      
    ])
    
    

   
  },
  watch: {
    windowWidth(newWidth) {
      if(newWidth >= 820) {
        this.showMenu = true
      } else {
        this.showMenu = false
      }
    }
  },
  async created () {
    this.showMobileMenuFunc();
    await this.syncAuthState();
    //this.checkRedirectToConsole()
    //this.resetCurrentCategory();

    //var tran = this.$route.params.t
    //if(tran==1){
      //this.showLanguageSelector = false;
    //}

    //get browser name and os
    const { detect } = require('detect-browser');
    const browser = detect();
    // handle the case where we don't detect the browser
    switch (browser && browser.name) {
      case 'chrome':
      case 'firefox':
        /* eslint-disable no-console */
        console.log('supported: ', browser.name);

        break;
      case 'edge':
        /* eslint-disable no-console */
        console.log('kinda ok');
        break;
    
      default:
        /* eslint-disable no-console */
        console.log('not supported');
    }


    localStorage.setItem('browserInfo', JSON.stringify({'name': browser.name, 'os': browser.os }))

    
    
    


  },
  mounted () {
    
    this.$nextTick (() => {
      window.addEventListener('resize',  () => {
        this.windowWidth = window.innerWidth;
      })
    })

    this.getCmsContent();

    this.showFooter = true;


    //Check if cookie exists allowing user to see site, otherwise there is an overlay
    //this.checkAccess()

    this.checkCookieAcceptance()

    



  },
  updated () {
    //show the search bar on the homepage only
    //this.setShowSearch(true)
   
  },
  methods: {
    ...mapActions('signin', {
      logout: 'logout'
    }),
    ...mapActions('cms', {
      setCmsContent: 'setCmsContent'
    }),
    ...mapActions('auth', [
      'syncAuthState',
      'setAllowAllCookies'
    ]),
    ...mapActions('search', [
      'setShowSearch'
    ]),
    closeCookieOverlay () {
      this.showCookieOverlay = false
    },


    onCookiePolicyClick () {
      this.$router.push('/cookies')
      
      
    },
    checkAccess () {
      if(this.isSupported(window.localStorage)) {
        if (JSON.parse(localStorage.getItem("userCanAccess")) === true) {
          this.showDemoOverlay = false;
        }else{
          this.showDemoOverlay = true;
        }
      }
    },
    checkCookieAcceptance () {
      if(this.isSupported(window.localStorage)) {
        if (
          JSON.parse(localStorage.getItem("userAcceptsCookies")) === true ||
          JSON.parse(localStorage.getItem("userAcceptsCookies")) === false
          ) {
          this.showCookieOverlay = false;
        }
      }
    },
    checkRedirectToConsole () {
      if(this.isSupported(window.localStorage)) {
        
        if (JSON.parse(localStorage.getItem("hasInitializedConsole")) === true) {
          /* eslint-disable no-console */
          console.log("pushing to console")
          this.$router.push('/console')
        }
      }
    },
    isSupported(storage) {
      try {
        const key = "__some_random_key_you_are_not_going_to_use__";
        storage.setItem(key, key);
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
    onPasscodeSubmit () {
      var lowerCaseCode = this.passcodeValue.toLowerCase()
      if(lowerCaseCode === this.passcode) {
        this.showDemoOverlay = false;
        if(this.isSupported(window.localStorage)) {
          localStorage.setItem("userCanAccess", JSON.stringify(true))
        }
      }else{
        /* eslint-disable no-console */
        console.log("wrong passcode")
      }
      this.passcodeValue = null
    },
    onCookieAccept () {
      this.showCookieOverlay = false
      if(this.isSupported(window.localStorage)) {
        localStorage.setItem("userAcceptsCookies", JSON.stringify(true))
      }
      this.setAllowAllCookies({allowAllCookies: true})
    },
    onCookieDecline () {
      this.showCookieOverlay = false
      if(this.isSupported(window.localStorage)) {
        localStorage.setItem("userAcceptsCookies", JSON.stringify(false))
      }
      this.setAllowAllCookies({allowAllCookies: false})
    },
    onCookiePref () {
      this.showCookieOverlay = false
      this.$router.push("/settings")
    },


    myTopNavFunction () {
      var x = document.getElementById("myTopnav");
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    },
    mySearchIconFunction () {

      this.$router.push('/routine')


      /*
      //show or hide
      if(!this.showSearch) {
        this.showSearch = true;
        //run down animation
        var d = document.getElementById("searchBox");
        d.className += " searchBoxDown";
      }else{
        this.showSearch = false;
      }
      */
      
    },
    onIdeaIconClick () {
      this.closeMenus()

    },
    showProfileMenuFunc () {
      this.showProfileMenu = (!this.showProfileMenu) ? true : false;
    },
    showMobileMenuFunc () {
      this.responsiveMenuFunc();
    },
    closeMenus () {
      var viewport = window.innerWidth;
      this.isMobile = (viewport <= 820) ? true : false;
      if(this.isMobile === true) {
        this.showMenu = false;
        this.showSearch = false;
      } else {
        this.showSearch = false;
      }
    },
    responsiveMenuFunc () {
      var viewport = window.innerWidth;
      this.isMobile = (viewport <= 820) ? true : false;

      //If mobile, this function call will toggle the main mobile nav menu - appear, disappear
      if(this.isMobile === false) {
        this.showMenu = true
      } else if (this.isMobile) {
        this.showMenu = (!this.showMenu) ? true : false;
        
      }



      //Search Menu - 
      if((this.showMenu === false) || ((this.showMenu === true) && (this.isMobile === false))) {
        this.showSearch = false   //if search is open, and mobile menu hamburger is clicked, close it
        
        
      }
      if((this.showProfileMenu === true ) && (this.showMenu === true) && (this.isMobile === true)) {
          this.showProfileMenu = false;
      }
      //comment out the below line when building for real site
      //this.showSearch = true
    },
    changeLang(e) {
      window.location = e.target.value;
      location.reload();
    },
    closeLanguageSelector () {
      this.showLanguageSelector = false;
    },
    async getCmsContent () {
      const workerR = new Worker()
      workerR.postMessage({cmd: "getCmsContent"})
      workerR.onmessage = e => {
        var data = JSON.parse(e.data.payload);
        this.setCmsContent(data)
      }
    },
    overlayOff () {
      document.getElementById("overlay").style.display = "none";
    },
    
    
  },
  beforeCreate () {
    if(!this.$store.state.auth) this.$store.registerModule('auth', authModule)
    if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
    if(!this.$store.state.search) this.$store.registerModule('search', searchModule)
  }
}
</script>

<style>


* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

html {
  height: 100%;
  position: relative;
}
body {
  overflow-x: hidden; 
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}




.bottomCookieNotice {
  position: fixed;
  display: block;
  z-index: 198;
  background-color: #222;
  bottom: 0;
  height: 160px;
  width: 100%;
  text-align: center;
}
.cookieNoticeText {
  position: relative;
  left: 12px;
  background-color: transparent;
  z-index: 99;
  display: block;
  width: 70%;
  margin: 0 auto;
  font-size: .65em;
  padding: 3px;
  padding-top: 10px;
  color: #999;
}

.cookieAcceptBtn {
  background-color: white;
  color: #555;
  width: 130px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 3px;
  text-align: center;
  display: inline-block;
  margin-left: 20px;
  margin-right: auto;
  margin-bottom: 5px;
  cursor: pointer;
}
.cookieDeclineBtn {
  background-color: rgb(39, 39, 39);
  color: #555;
  width: 130px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 3px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.cookiePrefBtn {
  background-color: rgb(39, 39, 39);
  color: #555;
  width: 170px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 3px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  margin-left: auto;
  cursor: pointer;
}
.cookieAcceptBtn:hover {
  background-color: gold;
  color: red;
}

#cookieNoticeClose {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #999;
  z-index: 120;
  font-size: 1.5em;
}

.emphasize {
  color: #c1c1c1;
  font-weight: bold;
}


#overlay {
  position: absolute; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.95); /* Black background with opacity */
  z-index: 3000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.overlayLogo {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 8px;
  background-color: transparent;
  z-index: 100;
}
.overlayLogo > img {
  height: 40px;
}


/* demo overlay */

#demoOverlay {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 5000px; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.95); /* Black background with opacity */
  z-index: 5000; /* Specify a stack order in case you're using a different order for other elements */

}


.demoOverlayLogo {
  text-align: center;
  display: inline-block;
  background-color: transparent;
  z-index: 80;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.demoOverlayLogo > img {
  height: 200px;
}
.codeInputHolder {
  position: relative;
  background-color: transparent;
  text-align: center;
}
.demoOverlayTextInput {
  display: inline-block;
  font-size: 16px;
  padding: 11px;
  border: 1px solid #ddd;
  background-color: rgb(210, 235, 255);
  margin-top: 10px;
}
.demoOverlayButton {
  display: block;
  cursor: pointer; /* Add a pointer on hover */
  background-color: rgb(109, 165, 248);
  text-align: center;
  font-size: 1.6em;
  color: white;
  padding: 4px;
  padding-left: 16px;
  padding-right: 16px;
  border: 2px solid #ddd;
  margin: 0 auto;
  margin-top: 20px;

}
.demoOverlayNotice {
  color: #777;
  width: 200px;
  margin: 0 auto;
  font-size: 1.1em;
}

.codeInputLabel {
  color: white;
  margin: 0 auto;
}


header {
  background-color: rgb(67, 67, 66);
  border-bottom: 1px solid rgba(0,0,0,.15);
  display: flex;
  flex-direction: column;
  text-align: right;
}

#logo {
  position: absolute;
  left: 12px;
  top: 10px;
  z-index: 1000;
}
#mainNav {
    /* position: relative; */
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }

header > nav {
  background-color: white;
  /* display: none; */
  flex: 1;
  transform: 300ms all ease;
}
  header nav > ul {
    list-style-type: none;
    padding: 0;
  }
    header nav > ul > li {
      border-bottom: 1px dotted rgba(0,0,0,.3);
      position: relative;
      text-align: center;
    }
    header nav > ul > li:last-of-type {
      border-bottom: none;
    }
    header nav > ul > li:hover {
      color: #fff;
      background-color: rgba(0,0,0,.6);
    }
      header nav > ul > li > a {
        display: block;
        color: rgba(0,0,0,.65);
        font-weight: 700;
        padding: 1.1rem 1rem;
        text-decoration: none;
        transition: 250ms all ease;
        font-size: 1.7em;
        height: 75px;
      }
      
  header nav > ul > li > nav > ul {
    list-style-type: none;
    padding: 0;
  }
 
  header nav > ul > li > nav {
    background-color: rgb(102, 102, 102);
    box-shadow: 0 2px 8px rgba(117, 111, 111, 0.6);
    /* display: none; */
    overflow: hidden;
    position: flex;
    width: 100%;
    text-align: center;
  }
    header nav > ul > li > nav > ul > li > a {
      color: rgba(255,255,255,.6);
      transition: 300ms all ease;
    }
      header nav > ul > li > nav > ul > li:hover > a {
        background-color: rgba(0,0,0,.6);
      }

  /* Hamburger icon */
  header > div#mainNav > div#navToggle {
    background-color: rgba(0,0,0,.1);
    float: right;
    display: block;    /* change this to 'block' when creating production version */
    /* position: absolute;
    right: 0;
    top: 0; */
    transition: 300ms all ease;
    height: 80px;
    padding: 30px;

  }
    

    .hamburgerIcon {
      top: 0;
      right: 0;
      height: 80px;
      z-index: 200;
      color: rgba(255,255,255,.85);
      display: block;
      font-size: 1.3em;
      font-weight: 600;
      text-decoration: none;
      transition: 300 ms all ease;
    }
    
    
    



  /* Search icon */
  header > div#mainNav > div#searchIcon {
    background-color:transparent;
    float: right;
    display: block;
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
    height: 80px;
    /* position: absolute;
    right: 0;
    top: 0; */
    transition: 300ms all ease;
  }
    header > div#mainNav > div#searchIcon:hover {
      background-color: rgba(0,0,0,.1);
    }
    header > div#mainNav > div#searchIcon > a {
      color: rgba(255,255,255,.85);
      display: block;
      font-size: 1.2em;
      font-weight: 600;
      padding: 0 1.4rem;
      text-decoration: none;
      transition: 300 ms all ease;
    }
      header > div#mainNav > div#searchIcon:hover > a {
        color: rgba(255,255,255,1);
      }
  /* Partner icon */
  header > div#mainNav > div#partnerIcon {
    background-color:transparent;
    float: right;
    display: block;
    /* position: absolute;
    right: 0;
    top: 0; */
    transition: 300ms all ease;
  }
    header > div#mainNav * div#partnerIcon:hover {
      background-color: rgba(0,0,0,.1);
    }
    header > div#mainNav > div#partnerIcon * a {
      color: rgba(255,255,255,.85);
      display: block;
      font-size: 1.3em;
      font-weight: 600;
      padding: 0 1.3rem;
      text-decoration: none;
      transition: 300 ms all ease;
    }
      header > div#mainNav > div#partnerIcon:hover * a {
        color: rgba(255,255,255,1);
      }
    #ideaIcon2 * a {
      color: rgba(255,255,255,.85);
      font-size: 1.1em;
      font-weight: 600;
      padding: 0 1rem;
      text-decoration: none;
      transition: 300 ms all ease;
    }

  #lang-container {
    display: block;
    text-align: center;
    background-color: #eec111;
    position: relative;
  }

  #lang-selector {
    background: transparent;
    background-color: #eec111;
    border: 0;
    padding: 3px;
  }
  #lang-close {
    display: inline;
    position: absolute;
    right: 10px;
    top: 4px;
    color: #333333;
  }

  #searchIcon2 > a {
    display: none;
  }

  #ideaIcon2 > a {
    display: none;
  }

  .partnerIcon {
    position: absolute;
    left: 10px;
    bottom: -45px;
    opacity: 0;
  }
  .partnerIconImg {
    width: 28px;
    height: 28px;
  }

@media all and (min-width: 550px) {
  .bottomCookieNotice {
    height: 130px;
  }
  
}

  /* Medium screens */
@media all and (min-width: 820px) {
  .bottomCookieNotice {
    height: 170px;
  }
  
  header > div#mainNav > div#navToggle {
    display: none;
  }
  header > div#mainNav > div#searchIcon {
    display: none;
  }
  
  #searchIcon2 > a {
    display: block;
  }

  header > div#mainNav > div#ideaIcon {
    display: none;
  }
  
  #ideaIcon2 > a {
    display: block;
  }


  header > nav {
    background-color: transparent;
    display: inline-block;
  }
  header > div#mainNav {
    background-color: purple;
    display: inline-block;
  }
  
  header > nav > ul {
    display: flex;
    flex-flow: row-reverse wrap;
  }


  header > nav > ul > li > a {
    color: rgba(255,255,255,.85);
    height: 80px;
    font-size: 1.1em;
  }
  header nav > ul > li {
    padding-right: 10px;
  }
  header nav > ul > li > nav > ul > li {
    padding-right: 0px;
  }
  header nav > ul > li > nav > ul > li > a {
    font-size: 1.25em;
  }
 

  header nav > ul > li > nav {
    box-shadow: 0 2px 2px rgba(189, 189, 189, 0.6);
    position: relative;
    z-index: 200;
    height: auto;
    width: 100%;
    
    
    
  }
  header nav > ul > li > nav > ul {
    display: flex;
    flex-direction: row;
  }

  .demoOverlayTextInput {
    width: 20%;
  }



  

}

@media all and (min-width: 1100px) {
  .bottomCookieNotice {
    height: 150px;
  }
}



/* Animation */

.fade-enter-active, .fade-leave-active {
  transition: all .25s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-27px);
  opacity: 0;
}

.fade2-enter-active, .fade-leave-active {
  transition: all .25s ease-in-out;
}
.fade2-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-27px) scale(-1.5);
  opacity: 0;
}




@keyframes searchDown {
  from {left: 0px; top: 0px}
  to {left: 0px; top: 400px}
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes searchDown {
  from {left: 0px; top: 0px}
  to {left: 0px; top: 400px}
}


</style>
