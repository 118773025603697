// Auth
export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_ANONYMOUS_PROFILE = 'SET_ANONYMOUS_PROFILE'
export const REMOVE_PROFILE = 'REMOVE_PROFILE'
export const SET_ROLE = 'SET_ROLE'
export const SET_LANG = 'SET_LANG'
export const SET_PROFILE_ISANONYMOUS_FLAG = 'SET_PROFILE_ISANONYMOUS_FLAG'
export const SET_AUTO_SIGNIN_FLAG = 'SET_AUTO_SIGNIN_FLAG'
export const SET_HAS_MINDERS = 'SET_HAS_MINDERS'
export const SET_TERMS = 'SET_TERMS'
export const SET_EDIT_MODE = 'SET_EDIT_MODE'
export const SET_COMMENTS_LAST_VIEWED_DATE = 'SET_COMMENTS_LAST_VIEWED_DATE'

export const SET_ALL_COOKIES = 'SET_ALL_COOKIES'
export const SET_ESSENTIAL_COOKIES = 'SET_ESSENTIAL_COOKIES'
export const SET_PERFORMANCE_COOKIES = 'SET_PERFORMANCE_COOKIES'
export const SET_ANALYTIC_COOKIES = 'SET_ANALYTIC_COOKIES'
export const SET_ADVERTISING_COOKIES = 'SET_ADVERTISING_COOKIES'
export const SET_SOCIAL_COOKIES = 'SET_SOCIAL_COOKIES'
export const SET_INITIALIZED_CONSOLE = 'SET_INITIALIZED_CONSOLE'

export const SYNC_COOKIES = 'SYNC_COOKIES'