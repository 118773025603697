<template>
  <div class="wrapper">
    <div class="card">
      <div v-if="currentView=='INSERT_VIEW_HERE'">
     
      <section class="cards" id="articleConsole">


        <article v-for="(featuredRoutine, index) in featuredRoutinesList" v-bind:key="`articlesList`+index">
          <router-link :to="`/evolve/` + featuredRoutine.id">
            <div class="item">
              <img class="article-img" :src="featuredRoutine.img" :alt="featuredRoutine.cr" v-html="featuredRoutine.cr"/>
              <h1 class="article-title">
                {{ featuredRoutine.im }}
              </h1>
            </div>
          </router-link>
        </article>


      </section>
      </div>
    </div>
  </div>
</template>

<script>
  //Some example imports you could have:
    import Worker from "worker-loader!@/worker";
    //import categoryModule from '@/store/category'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    //import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "FeaturedRoutines",
    components: {
      
    },
    props: {
      //minder: Object
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        featuredRoutinesList: [],
        
      }
    },
    computed: {
      
    },
    watch: {

    },
    methods: {
      //...mapActions('category', [
      //  'setCurrentCategory'
      //]),

      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getFeaturedRoutines"})
          workerA.onmessage = e => {
            var articlesList = JSON.parse(e.data.payload)
            for (var key  in articlesList) {
              this.featuredRoutinesList.push(articlesList[key])
            } 
          }

          resolve()
        })
      },
      

    },
    beforeCreate () {
      //if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)
    },
    async created () {
      await this.fetchTheData();
    
    },
    mounted () {
      //this.$refs.searchBox.focus()
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 4px;
}


.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 2px;
  max-width: 700px;
}

article {
  position: relative;
  overflow: hidden;
}
.article-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.article-title {
  font-family: 'Quicksand';
  font-weight: 900;
  position: absolute;
  bottom: 2px;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.70);
}

@media all and (min-width: 300px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-gap: 2px;
    max-width: 600px;
    margin: 0 auto;
    
  }
}


@media all and (min-width: 370px) {
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-gap: 2px;
    max-width: 700px;
    margin: 0 auto;
    
  }
}

/*
@media all and (min-width: 500px) {
  .cards {
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 4px;
  max-width: 700px;
  margin: 0 auto 10px;
    
  }

}
*/
/*
  @media all and (min-width: 600px) {
  .cards {
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 2px;
  max-width: 1000px;
  margin: 0 auto 10px;
    
  }
  }
*/

@media all and (min-width: 750px) {
  .cards {
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 2px;
  max-width: 900px;
  margin: 0 auto;
    
  }
}


@media all and (min-width: 900px) {
  .cards {
    display: grid;
  grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
  grid-gap: 4px;
  max-width: 1300px;
  margin: 0 auto;
    
  }
}









</style>