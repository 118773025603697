//import firebase from '@/firebase'
//import router from '@/router'
//import store from '@/store'

import {
  SET_SHOW_SEARCH,
  SET_SHOW_SEARCH_ICON,

} from './mutations'

const state = {
  showSearch: false,
  showSearchIcon: true,
}

const getters = {
  showSearch: ({showSearch}) => showSearch,
  showSearchIcon: ({showSearchIcon}) => showSearchIcon,
}

const mutations = {

  [SET_SHOW_SEARCH] (state, bool) {
    state.showSearch = bool
  },
  [SET_SHOW_SEARCH_ICON] (state, bool) {
    state.showSearchIcon = bool
  },
}

const actions = {
  setShowSearch ({commit},payload) {
    commit(SET_SHOW_SEARCH, payload)
  },
  setShowSearchIcon ({commit},payload) {
    commit(SET_SHOW_SEARCH_ICON, payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}