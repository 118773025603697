<template>
  
  <div class="updatesHolder">
    
      
        <div class="updates" v-for="(featuredArticle, index) in featuredArticlesList" v-bind:key="`articlesList`+index">
          <router-link :to="featuredArticle.r">
          <div class="updateHolder">
            <div class="starHolder">
              <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
            </div>
            <span class="headlineText">{{ featuredArticle.h }}</span>&nbsp;&nbsp;
            <div class="updateText">
              {{ featuredArticle.sh }}
            </div>
          </div>
          </router-link>
        </div>
       
      
    
  </div>

</template>

<script>
  //Some example imports you could have:
    import Worker from "worker-loader!@/worker";
    //import categoryModule from '@/store/category'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    //import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "FeaturedRoutines",
    components: {
      
    },
    props: {
      //minder: Object
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        featuredArticlesList: [],
        
      }
    },
    computed: {
      
    },
    watch: {

    },
    methods: {
      //...mapActions('category', [
      //  'setCurrentCategory'
      //]),

      async fetchTheData () {
        return new Promise((resolve) => {
          //first try to fetch from cache

          //use a web worker
          const workerA = new Worker()
          workerA.postMessage({cmd: "getFeaturedArticles"})
          workerA.onmessage = e => {
            var articlesList = JSON.parse(e.data.payload)
            for (var key  in articlesList) {
              this.featuredArticlesList.push(articlesList[key])
            } 
          }

          resolve()
        })
      },
      

    },
    beforeCreate () {
      //if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)
    },
    async created () {
      await this.fetchTheData();
    
    },
    mounted () {
      //this.$refs.searchBox.focus()
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  padding: 4px;
}

  .updatesHolder {
    padding: 7px;
    background-color: rgb(31, 31, 31);
    display: flex;
    flex-direction: column;
    width: 100%;
    
    

  }
  .flex-item {
    flex: 1 1 auto;
  }
  .updates {
    background-color: rgb(67,67,66);
    border: 1px solid rgb(46, 46, 46);
    border-radius: 7px;
    padding: 7px;
    padding-top: 20px;
    min-height: 100px;
    display: block;
    font-size: .9em;
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
    flex: 1;
  }

  .updateHolder {
    color: white;
    text-align: left;
  }


  .starHolder {
    position: absolute;
    top: -10px;
    left: -15px;
    z-index: 20;

  }
  .starImg {
    width: 90px;
    opacity: 0.17;
  }
  .dateText {
    position: absolute;
    top: 1px;
    right: 4px;
    font-weight: 400;
    font-size: .9em;
    margin-left: 40px;
    margin-top: 5px;
    color:rgb(141, 141, 141);
  }
  .headlineText {
    font-weight: 700;
    font-size: 1em;
    margin-left: 40px;
    padding-top: 40px;
    background-color: transparent;
  }
  .updateText {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    padding-top: 5px;
    margin-left: 40px;
    margin-bottom: 15px;
    padding-right: 20px;
    font-size: 1.25em;
    color:rgb(221, 221, 221);
    background-color: transparent;
  }




article {
  position: relative;
  overflow: hidden;
}
.article-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.article-title {
  font-family: 'Quicksand';
  font-weight: 900;
  position: absolute;
  bottom: 2px;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.70);
}

@media all and (min-width: 700px) {
  .updatesHolder {
    flex-direction: row;
  }
  .updates {
    padding: 8px;
    padding-top: 22px;
    border: 3px solid rgb(46,46,46);
    margin-right: 3px;
    margin-left: 3px;
    font-size: 1em;
    min-height: 140px;
    flex-grow: 1;
    flex-basis: 0;
    
  }
  .headlineText {
    font-weight: 700;
    font-size: 1em;
    margin-left: 40px;
    padding-top: 40px;
    background-color: transparent;
  }

  .updateText {
    padding-top: 5px;
    margin-left: 40px;
    margin-bottom: 15px;
    padding-right: 0px;
    font-size: 1.25em;
    color:rgb(221, 221, 221);
    background-color: transparent;
    width: 100%;
  }
  .starImg {
    width: 110px;
  }
  .dateText {
    margin-left: 60px;
  }
  .starHolder {
    top: -10px;
  }
}
















</style>