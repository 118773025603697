<template>
  <div class="wrapper">
    <footer class="footer">
      <div class="references">
        <br /><a href="https://www.redbubble.com/people/heedi/works/45143293-heedi?asc=u" alt="Grab a heedi T-shirt">Grab a heedi T-shirt!</a><br /><br />
      </div>  


      <div class="footerLinks">
        
        <div class="footerContentBox" id="footerAboutLink">
          <router-link to="/about"><a :style="footerStyle" href="#">About</a></router-link>
        </div>

        <div class="footerContentBox" id="footerAboutLink">
          <router-link to="/support"><a :style="footerStyle" href="#">How-To</a></router-link>
        </div>
        
        <div class="footerContentBox" id="footerFeedbackLink">
          <router-link to="/communicate/feedbackOnheedi/now"><a :style="footerStyle" href="#">Feedback</a></router-link>
        </div>
        
        <div class="footerContentBox" id="footerSubscribeLink">
          <router-link to="/subscribe"><a :style="footerStyle" href="#">Subscribe</a></router-link>
        </div>
      </div>
      <br>
      
      <div class="footerCopyright">
        <router-link to="/privacy">Privacy</router-link>
        &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/terms">Terms of Use</router-link>
        &nbsp;&nbsp;&nbsp;&nbsp;<router-link to="/cookies">Cookie Policy</router-link>
        &nbsp;&nbsp;&nbsp;&nbsp;<router-link v-if="loggedIn" to="/settings">Settings</router-link>
        <router-link v-else to="/settings">Cookie Settings</router-link><br><br>
        <span style="font-size: .75em;">heedi &copy; 2021&nbsp;Patent Pending</span>
      </div>
      <router-link :to="{path:'/', query: {d: 1}}">
        <div class="footerLogo">
          <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
        </div>
      </router-link>
    </footer>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    //import feelingModule from '@/store/feeling'
    import { mapGetters } from 'vuex'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'

  export default {
    name: "VueFooter",
    components: {
      
    },
    props: {
      darkText: {
        type: Boolean
      },
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        footerStyle: null,
      }
    },
    computed: {
      ...mapGetters('auth', [
        'loggedIn',
      ])
    },
    watch: {

    },
    methods: {
      //...mapActions('pattern', [
      //  'createMeanPatternInFirestore'
      //]),
      onFeedbackClick () {
        //document.getElementById("overlay").style.display = "block";

      },
      onCopyrightClick (page) {
        this.$router.push(page)
      }
      

    },
    beforeCreate () {
      //if(!this.$store.state.feeling) this.$store.registerModule('feeling', feelingModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
    
    },
    mounted () {
      if(this.darkText) {
        this.footerStyle = 'color: rgb(90, 90, 90);'
      }else{
        this.footerStyle = 'color: white;'
      }
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
.references {
  transform: translateY(-15px);
  height: 80px;
  background-color: purple;
}
.references > a {
  text-decoration: underline;
  font-family: 'Quicksand';
  font-size: 1.3em;
}
.references > a:link, a:visited {
  color: rgb(146, 146, 146);
}
.footer {
  position: relative;
  display: block;
  background-color: transparent;
  text-align: center;
  padding-top: 10px;
  margin-top: 20px;
}
  .footerContentBox {
    font-family: 'Kreon';
    text-align: center;
    font-weight: 900;
    align-content: center;
    background-color: transparent;
    width: 100%;
    padding: 12px;
    font-size: 1.4em;
  }
  .footerLinks {
    display: grid;
    grid-template-columns: auto auto;
    width: 60%;
    margin: 0 auto;
  }
  .darkText {
    color: rgb(90, 90, 90);
  }
  .lightText {
    color: white;
  }
  .footerCopyright {
    background-color: transparent;
    bottom: 2px;
    display: block;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: .8em;
  }
  .footerPrivacyLink {
    position: absolute;
    bottom: 2px;
    left: 25%;
    display: block;
    color: #999;
    font-size: .95em;
  }

  .footerLogo {
    position: absolute;
    left: 8px;
    bottom: 80px;
  }
  .footerLogo > img {
    height: 60px;
  }
  
  div#footerPurposeLink > a:visited, div#footerPurposeLink > a:active, div#footerPurposeLink > a:link {
    color: #fff;
    text-decoration: none;
  }
  div#footerFeedbackLink > a:visited, div#footerFeedbackLink > a:active, div#footerFeedbackLink > a:link {
    color: #fff;
    text-decoration: none;
  }
  div#footerAboutLink > a:visited, div#footerAboutLink > a:active, div#footerAboutLink > a:link {
    color: #fff;
    text-decoration: none;
  }

  .references {
    display: block;
    width: 100%;
    background-color: transparent;
    position: relative;
    font-size: 1.5em;


  }

@media screen and (min-width: 560px) {

  .footerContentBox {
    font-size: 1.4em;
    height: 80px;
  }
  
  .footerLogo {
    left: 17px;
  }
  .footerLogo > img {
    height: 68px;
  }
  .footerCopyright {
    bottom: 2px;
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 1.1em;
  }
  

}

</style>